import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


const Matjar = () => {
    const currentdomain = window.location.origin.split(':3000')[0];

    const urla = `${currentdomain}/bk/get_all.php`;

    const [datas, setdatas] = useState([]);
    const [loading, setLoading] = useState(true);
    const prod_id = "a";
    const navigate = useNavigate();

    useEffect(

        function () {
            document.title = ('DZ_Ecom');
            fetchData();
            // eslint-disable-next-line 
        }, [urla]
    );

    function logout() {
        Cookies.remove('authToken');
        navigate('/login', { replace: true });
    }
    async function fetchData() {

        try {

            //   'https:react.osrati.shop/bk/post.php'  'http://localhost/backend/backend_rect.php'
            const response = await fetch(urla, {
                //   const response = await fetch(`${currentdomain}/bk/post_order.php`, {
                method: 'POST',
                headers: {
                    // 'Content-Type': 'text/html',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',

                },
                // mode: 'cors',
                body: JSON.stringify({ prod_id }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');

            }
            const data = await response.json();
            setdatas(data.products);
            /* (data ==null) ?
             navigate('not_found', { replace: true })
               :   setdatas(data.products)*/

            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            setdatas([]);
            //   navigate('not_found', { replace: true }); //redirect to not found 404

        }

    }

    return (
        <>


            <div>

                <div className="w-full   mx-auto  text-center border px-1 mds:px-11 ">

                    <div className="headd">
                        <div className="titla pt-4">
                            <ul >
                                <li className="font-bold text-2xl lgg:text-3xl underline underline-offset-1 decoration-green-700 decoration-solid">تصنيفات المتجر</li>
                                <li className="font-normal text-xl lgg:text-2xl"> نتوفر على جميع المنتوجات التي ستحتاجها</li>
                            </ul>
                        </div>
                        <div className="px-18 py-4 mx-3 max-h-full grid grid-cols-1 mdd:grid-cols-2  gap-3 content-center ">
                            <div className="h-44 mds:h-72 mdd:h-96  lgg:h-[480px] w-full  mdd:col-span-2 bg-cover bg-center  hover:cursor-pointer   bg-[url('https://i0.wp.com/demo.jude-theme.site/wp-content/uploads/2023/12/chambre-moderne-vide-meubles-_1_-630x345-1.webp?resize=630%2C345')]" >
                                <div className="w-full h-full flex  justify-start items-end backdrop-brightness-75 hover:backdrop-brightness-100 border-separate border-2 border-green-200" >
                                    <ul className="ms-6 pb-6">
                                        <li className="text-white font-bold text-xl mdd:text-2xl">لوازم المطبخ</li>
                                        <li className='orderbt   flex justify-center m-1 bg-green-600 group-hover:bg-green-800 text-white font-bold text-sm mdd:text-lg rounded-sm   h-8 mdd:h-11 active:bg-yellow-400 hover:cursor-pointer  '><button onClick={logout}>تسوق الآن</button></li>
                                    </ul>
                                </div>

                            </div>
                            <div className="h-44 mds:h-72 lgg:h-96 w-full   bg-cover bg-center hover:cursor-pointer  bg-[url('https://i0.wp.com/demo.jude-theme.site/wp-content/uploads/2023/12/style-salon-moderne_53876-144814-1.jpeg?resize=630%2C345')]" >
                                <div className="w-full h-full backdrop-brightness-75 hover:backdrop-brightness-100 flex  justify-start items-end border-separate border-2 border-green-200" >
                                    <ul className="ms-6 pb-6">
                                        <li className="text-white font-bold text-xl mdd:text-2xl">لوازم المنزل</li>
                                        <li className='orderbt   flex justify-center m-1 bg-green-600 group-hover:bg-green-800 text-white font-bold text-sm mdd:text-lg rounded-sm   h-8 mdd:h-11 active:bg-yellow-400 hover:cursor-pointer  '><button >تسوق الآن</button></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="h-44 mds:h-72 lgg:h-96 w-full  bg-cover bg-center hover:cursor-pointer   bg-[url('https://i0.wp.com/demo.jude-theme.site/wp-content/uploads/2023/12/espace-travail-gamer_127657-18683-630x345-1.jpeg?resize=630%2C345')]">
                                <div className="w-full h-full flex  justify-start items-end backdrop-brightness-75 hover:backdrop-brightness-100 border-separate border-2 border-green-200 " >
                                    <ul className="ms-6 pb-6">
                                        <li className="text-white font-bold text-xl mdd:text-2xl">الكترونيات</li>
                                        <li className='orderbt   flex justify-center m-1 bg-green-600 group-hover:bg-green-800 text-white font-bold text-sm mdd:text-lg rounded-sm   h-8 mdd:h-11 active:bg-yellow-400 hover:cursor-pointer  '><button >تسوق الآن</button></li>
                                    </ul>
                                </div>
                            </div>


                        </div>

                    </div>


                    <div>
                        <div className="titla pt-4">
                            <ul >
                                <li className="font-bold text-2xl lgg:text-3xl underline underline-offset-1 decoration-green-700 decoration-solid">منتجاتنا أصلية وذات جودة عالية</li>
                                <li className="font-normal text-xl lgg:text-2xl">تصفح قائمة المنتجات الأكثر رواجا!</li>
                            </ul>
                        </div>
                    </div>

                    {/* loopppppppppppppppppppppppppppppppppppppppppppppppp */}



                    {loading ?

                        (
                            <p>جاري التحميل</p>
                        ) :
                        (
                            <div className="continar-card grid grid-cols-2 mdd:grid-cols-3 lgg:grid-cols-5 py-3 ">
                                {datas.map(item => (
                                    ((parseInt(item.hidden)) === 0) &&
                                    <Link key={item.product_id} to={'/product/' + (item.title_id)} > <div className="group mt-6 hover:mt-1 bg-white w-full  p-3  text-center hover:cursor-pointer">
                                        <ul className="border-separate border-2 border-gray-100 group-hover:border-gray-400">
                                            <li >    <img className="h-48 mdd:h-80 w-full " src={item.img_default} alt='' /></li>
                                            <li className="prodname font-bold text-xl text-black line-clamp-1 ">{item.title}</li>
                                            <li>
                                                <span className="price m-3 text-green-600 font-semibold text-lg">{item.price} دج</span>
                                                <span className="oldprice text-gray-400 line-through text-sm">{item.oldprice} دج</span>
                                            </li>
                                            <li className='orderbt   flex justify-center m-4 bg-green-600 group-hover:bg-green-800 text-white font-bold text-lg rounded-sm   h-11 active:bg-yellow-400 hover:cursor-pointer  '><button >اشتري الآن</button></li>
                                        </ul>
                                    </div>
                                    </Link>
                                ))}
                            </div>
                        )}




                    <div>
                        <div className="titla pt-4">
                            <ul >
                                <li className="font-bold text-2xl lgg:text-3xl underline underline-offset-1 decoration-green-700 decoration-solid">عروض خاصة</li>
                                <li className="font-normal text-xl lgg:text-2xl">تخفيظات طيلة هذا الشهر</li>
                            </ul>
                        </div>


                        {loading ? (
                            <p>جاري التحميل</p>
                        )
                            : (
                                <div className="continar-card grid grid-cols-2 mdd:grid-cols-3 lgg:grid-cols-5 py-3">
                                    {datas.map(item => (
                                        // <li key={item.id}>{item.title}</li>
                                        ((parseInt(item.hidden)) === 0) &&
                                        <Link key={item.product_id + 1} to={'/product/' + (item.title_id)}>   <div className="group mt-6 hover:mt-1 bg-white w-full h-auto p-3  text-center hover:cursor-pointer">
                                            <ul className="border-separate border-2 border-gray-100 group-hover:border-gray-400">
                                                <li >    <img className="h-48 mdd:h-80 w-full " src={item.img_default} alt='' /></li>
                                                <li className="prodname font-bold text-xl text-black line-clamp-1 ">{item.title}</li>
                                                <li>
                                                    <span className="price m-3 text-green-600 font-semibold text-lg">{item.price} دج</span>
                                                    <span className="oldprice text-gray-400 line-through text-sm">{item.oldprice} دج</span>
                                                </li>
                                                <li className='orderbt   flex justify-center m-4 bg-green-600 group-hover:bg-green-800 text-white font-bold text-lg rounded-sm   h-11 active:bg-yellow-400 hover:cursor-pointer  '><button >اشتري الآن</button></li>
                                            </ul>
                                        </div>
                                        </Link>
                                    ))}
                                </div>
                            )



                        }



                    </div>
                </div>
            </div >
        </>
    )
}

export default Matjar;