import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const NavBar = () => {

    const [mobmenu, setmobmenu] = useState(false);
    const [search, setsearch] = useState(false);
    const [patha, setpatha] = useState('#');
    const [hidde, sethidde] = useState(false);
    const excludedRoutes = ['login', 'admin', 'thank_page', 'auth'];

    function show_mo_menu() {
        mobmenu ? setmobmenu(false) : setmobmenu(true);

    }
    function show_search() {
        search ? setsearch(false) : setsearch(true);

    }


    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        setpatha(location.pathname);
        (!excludedRoutes.includes((window.location.pathname.split('/')[1]))) ? sethidde(true) : sethidde(false);
        // eslint-disable-next-line
    }, [location.pathname]);


    return (
        <>

            {hidde && <div>
                <div className={mobmenu ? "hidden" : "navbar"}>

                    <div className='title bg-green-600  h-auto  text-sm text-white text-center font-semibold py-3' >  <Link to="tel:0777068019">أطلب الآن! ودفع عند الاستلام | هاتف 👈 0777068019</Link></div>
                    <div className='bg-slate-50'>
                        <div className='  flex justify-between items-center p-2 mx-2 '>

                            <div className="icon rounded-full bg-gray-200 py-1 px-2 cursor-pointer lgg:hidden" onClick={show_mo_menu}>
                                {/* <i color="blue" className='bx bx-menu bx-sx bx-border-circle bg-gray-200 cursor-pointer' onClick={show_mo_menu}></i> */}

                                <FontAwesomeIcon icon={faBars} size="sm" />


                            </div>
                            <div>
                                <Link to="/">
                                    <img src="https://cdn.youcan.shop/stores/f58557b5767b4b1a03bdbe3baa00f008/others/FwQFCsc8Rp56LiMNBKDntvNrCOhVPXaUb5yRkmUT.png" alt="" className='image h-16 w-24' />
                                </Link>
                            </div>


                            <div className='lgs:hidden'>
                                <ul className="middllink grid grid-cols-5 justify-between font-bold text-center ">
                                    <li>  <Link className={(patha === '/') ? 'ps-2 hover:text-green-500  text-green-500' : 'ps-2  hover:text-green-500 '} to="/">الرئيسية</Link></li>
                                    <li>  <Link className={(patha === '/shipping-delivery') ? 'ps-2  hover:text-green-500 text-green-500' : 'ps-2  hover:text-green-500'} to="/shipping-delivery">الشحن والتسليم</Link></li>
                                    <li> <Link className={(patha === '/how-to-pay') ? 'ps-2  hover:text-green-500 text-green-500' : 'ps-2  hover:text-green-500'} to="/how-to-pay">طرق الدفع</Link></li>
                                    <li> <Link className={(patha === '/contact-us') ? 'ps-2  hover:text-green-500 text-green-500' : 'ps-2  hover:text-green-500'} to="/contact-us">اتصل بنا</Link></li>
                                </ul>

                            </div >
                            <div className="icon rounded-full bg-gray-200 py-1 px-2 cursor-pointer " onClick={show_search}>
                                {/* <i className='bx bx-search bx-sx mdd:bx-md bx-border-circle bg-gray-200 cursor-pointer' onClick={show_search}></i> */}
                                <div className='lgs:hidden'>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />

                                </div>
                                <div className='lgg:hidden'>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />

                                </div>
                            </div>



                        </div >
                        <div className={search ? 'search  text-center mb-3' : 'hidden'}>

                            <input className=" mt-1 border-solid border-2 border-gray-400  focus:border-green-600 focus:outline-none font-semibold text-sm " type="text" name="full_name" placeholder="بحث" />

                        </div>

                    </div >
                </div >



                <div id="dropdown" className={mobmenu ? "z-10  bg-gray-50 divide-y divide-gray-100 rounded-lg shadow w-full text-center " : "hidden"}>
                    <ul className="py-2 text-sm text-black dark:text-black" >
                        <li>
                            <Link to="/" className={(patha === '/') ? "block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-60 border text-green-500" : "block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-60 border text-lg"} onClick={show_mo_menu}>الرئيسية</Link>
                        </li>
                        <li>
                            <Link to="/shipping-delivery" className={(patha === '/shipping-delivery') ? "block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-60 border text-lg text-green-500" : "block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-60 border text-lg"} onClick={show_mo_menu}>الشحن والتسليم</Link>
                        </li>
                        <li>
                            <Link to="/how-to-pay" className={(patha === '/how-to-pay') ? "block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-60 border text-lg text-green-500" : "block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-60 border text-lg"} onClick={show_mo_menu}>طرق الدفع</Link>
                        </li>

                        <li>
                            <Link to="/contact-us" className={(patha === '/contact-us') ? "block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-60 border text-lg text-green-500" : "block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-60 border text-lg"} onClick={show_mo_menu}>اتصل بنا</Link>
                        </li>
                        <li className='mt-3 cursor-pointer hover:text-green-500' onClick={show_mo_menu}>
                            <FontAwesomeIcon icon={faXmark} size="2xl" style={{ color: "#404c54", }} />

                        </li>
                    </ul>
                </div>
            </div>}
        </>
    )
}
export default NavBar;