import React, { useEffect, useState, useRef } from "react";
//import 'quill/dist/quill.snow.css';
//import ReactQuill from 'react-quill';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faBasketShopping, faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';


// import Editor from "./Editor";
// import { richetexta } from "../Routta";
import {
    GridContextProvider,
    GridDropZone,
    GridItem,
    swap,
    move
} from "react-grid-dnd";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Editor } from '@tinymce/tinymce-react';
import Cookies from 'js-cookie';

const Addproduct = (props) => {
    const navigate = useNavigate();
    const token = Cookies.get('token');
    const currentdomain = window.location.origin.split(':3000')[0];
    const urla = `${currentdomain}/bk/edit_product.php`;
    const [showprice, setshowprice] = useState(false);
    const [showphotos, setshowphotos] = useState(true);
    const [showdiscript, setshowdiscript] = useState(true);
    const [shovariace, setshovariace] = useState(false);
    const [similaire, setsimilaire] = useState(false);
    const [azos, setazos] = useState(false);
    const [pixelshow, setpixelshow] = useState(false);
    const fileInputRef = useRef();
    const [progress, setProgress] = useState(0);
    const [unids, setunids] = useState('');
    // const [ContentTinymce, setContentTinymce] = useState('');
    const [linka, setlinka] = useState('');

    // const { state } = props.location;
    /*  const location = props;
    const state = location;
     const { titles } = state;*/

    // const [titles, settitles] = useState('');
    // const contexta = useContext(richetexta);

    const paths = window
        .location
        .pathname
        .split("/")
        .filter(path => path !== "");
    const prod_id = paths[paths.length - 1];

    const [formData, setFormData] = useState({
        price: '',
        oldprice: '',
        cost: '',
        titles: '',
        discription: '',
        link: '',
        imgs: '',
        defimg: '',
        pixel_type: '',
        pixel_list: [],
        unids: '',
    });
    // const [imgstate, setimgstate] = useState(0);
    const [items, setItems] = useState({
        left: [
            // { position: 1, name: "ben1", link: "https://img.doodoo.love/_next/image?url=https%3A%2F%2Fresource.doodoo.love%2F3USG0h%2F1gtlou72t0vrpkjgam4dcefbxej2p3vr%2F512x512.jpg&w=408&q=75" },
            // { position: 2, name: "joe1", link: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiB5YB6ZBrXuZGD0l6YumM9qckBBYxkg60kxlE1TjEBo-Zf_J0bitXvCEwvmMaImzdMC7UjQaKZlCZqkKS_aau18PLIWb3UytYIlHU1qSuBRcgnKCP17MtIbGhZVYYfvp5Y5NcWvPhXy_s/s1600/Dai+png.png" },
            // { position: 3, name: "jason1", link: "https://static0.gamerantimages.com/wordpress/wp-content/uploads/2022/01/Ryuk-Death-Note.jpg" },
            // { position: 4, name: "chris1", link: "https://target.scene7.com/is/image/Target/GUEST_69fa4299-f152-46e5-a62e-15dec9210711?wid=488&hei=488&fmt=pjpeg" },
            // { position: 5, name: "heather1", link: "https://ygo-assets-entities-us.yougov.net/5c1c2911-2d03-11e6-a4bd-71dbf5f2854a.jpg?ph=264" }

        ],
        right: [
            { position: 8, name: "ben1", link: "https://img.doodoo.love/_next/image?url=https%3A%2F%2Fresource.doodoo.love%2F3USG0h%2F1gtlou72t0vrpkjgam4dcefbxej2p3vr%2F512x512.jpg&w=408&q=75" },
            { position: 7, name: "joe1", link: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiB5YB6ZBrXuZGD0l6YumM9qckBBYxkg60kxlE1TjEBo-Zf_J0bitXvCEwvmMaImzdMC7UjQaKZlCZqkKS_aau18PLIWb3UytYIlHU1qSuBRcgnKCP17MtIbGhZVYYfvp5Y5NcWvPhXy_s/s1600/Dai+png.png" },
            { position: 8, name: "jason1", link: "https://static0.gamerantimages.com/wordpress/wp-content/uploads/2022/01/Ryuk-Death-Note.jpg" },
            { position: 9, name: "chris1", link: "https://target.scene7.com/is/image/Target/GUEST_69fa4299-f152-46e5-a62e-15dec9210711?wid=488&hei=488&fmt=pjpeg" },
            { position: 10, name: "heather1", link: "https://ygo-assets-entities-us.yougov.net/5c1c2911-2d03-11e6-a4bd-71dbf5f2854a.jpg?ph=264" }

        ]
    });
    const [imagePaths, setImagePaths] = useState([]);
    const editorRef = useRef(null);
    // const handletinymceChange = () => {
    //     if (editorRef.current) {
    //          editorRef.current.setContent('<p style="text-align: center;"><span style="font-size:24pt; color:#843fa1;">kjhkjh</span></p>');
    //         alert(editorRef.current.getContent());
    //         setContentTinymce(editorRef.current.getContent())


    //     }
    // };
    const [inputs, setInputs] = useState([{ id: Date.now(), value: '', name: '' }]);

    const [selectedValue, setSelectedValue] = useState('0');
    const [valid_link, setvalid_link] = useState(false);
    const [isedit, setisedit] = useState(false);
    function onChanges(sourceId, sourceIndex, targetIndex, targetId) {
        console.log(targetId, sourceId);
        if (targetId) {
            const result = move(
                items[sourceId],
                items[targetId],
                sourceIndex,
                targetIndex
            );
            return setItems({
                ...items,
                [sourceId]: result[0],
                [targetId]: result[1]
            });
        }

        const result = swap(items[sourceId], sourceIndex, targetIndex);
        return setItems({
            ...items,
            [sourceId]: result
        });
    }
    const navigateToProducts = () => {
        //window.open('/admin/product', '_blank');
        navigate('/admin/product');
    };
    const navigateToPreview = () => {
        window.open('/product/' + linka, '_blank');

    };
    async function rmvrow(positions) {

        try {
            const name = link_to_name(items.left.find(item => item.position === positions).link);
            const response = await fetch(currentdomain + '/bk/upload_img.php', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },

                body: JSON.stringify({ name, unids }),

            });

            if (!response.ok) {
                throw new Error('Network response was not ok');

            }

            const data = await response.json();

            (data.success === 1) ?

                setItems(prevData => ({
                    ...prevData,
                    // eslint-disable-next-line
                    ['left']: prevData['left'].filter(item => item.position !== positions)
                })) : alert(data.datas + name)


        } catch (error) {
            console.error('Error fetching data:', error);
            // alert('rrrrrrrrrrrrrr')
        }



    }
    function addrow(dir) {
        // setbordersiza();

        setItems(prevData => ({
            ...prevData,
            left: [...prevData.left, { position: (items.left.length + 1), name: "ben8" + (items.left.length + 1), link: dir }]
        }));

        // alert(currentdomain + "/uploads/" + "temp_" + unids + dir)

    }
    function showprices() {
        setshowprice(!showprice);
    }
    function showphoto() {
        setshowphotos(!showphotos);
    }
    function showvaria() {
        setshovariace(!shovariace);
    }
    function showsim() {
        setsimilaire(!similaire);
    }
    function showazzo() {
        setazos(!azos);
    }
    function showpixel() {
        setpixelshow(!pixelshow);
    }
    function showdiscription() {
        setshowdiscript(!showdiscript);
    }




    useEffect(

        function () {


            async function first() {
                document.title = 'اضافة منتج جديد';
            }
            async function fetchData() {

                try {

                    const response = await fetch(urla, {

                        method: 'POST',
                        headers: {
                            // 'Content-Type': 'text/html',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': `Bearer ${token}`,

                        },
                        //  mode: 'cors',
                        body: JSON.stringify({ prod_id }),
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');

                    }
                    const data = await response.json();
                    if (data === null) {
                        //   navigate('/not_found', { replace: true })
                        // console.log('nullllllll')
                    }
                    else {

                        if (data.success === 1) {
                            //alert(data.datas.Product[0].product_name)
                            setFormData(prevState => ({
                                ...prevState,
                                titles: data.datas.Product[0].product_name,
                                title_id: data.datas.Product[0].title_id,
                                price: data.datas.Product[0].price,
                                oldprice: data.datas.Product[0].oldprice,
                                discription: data.datas.Product[0].description
                            }));
                            setlinka(data.datas.Product[0].title_id);
                            //         addrow(data.datas.Product[0].img_default)
                            const imageUrls = data.datas.Product[0].imgs.split(',');
                            imageUrls.map((url, index) => (
                                addrow(url)
                            ))

                            setSelectedValue(data.datas.Product[0].pixel_type);

                            const { pixels } = data.datas.pixels;
                            //alert(data.datas.pixels[0].pixel_id)
                            // console.log('zzzzzzzzzzzzzzzzzzzzzz' + pixels);
                            // Map the fetched data to match the state structure
                            const newInputs = pixels.map(pixel => ({
                                id: pixel.pixel_id, // Unique identifier for each row
                                value: pixel.pixel_data, // Data to display in the input field
                                name: pixel.pixel_name,
                            }));
                            setInputs(newInputs);
                        }
                        else if (data.success === 0) {
                            alert(data.datas)
                        }
                    }

                } catch (error) {
                    console.log('errrrrrrrrrrrrrrrrrrrr');
                    console.error('Error fetching data:', error);

                }

            }

            first();
            if (unids === '') {
                setunids(uuidv4());
            }
            if ((prod_id !== 'add_new') && (prod_id !== '*')) {
                setisedit(true);
                fetchData();
            }

            //   second();


            /*   const interval = setInterval(() => checkAndUpdatePaths(), 5000); // Adjust interval as needed
            return () => clearInterval(interval);*/


            // eslint-disable-next-line
        }, []
    );


    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current;

            // Handle the removal of images
            const handleNodeChange = (e) => {
                if ((e.key === 'Backspace') || (e.key === 'Delete')) {
                    handleEditorChange();
                }

            };

            // editor.on('NodeChange', handleNodeChange);
            editor.on('keydown', function (e) { handleNodeChange(e) });

            return () => {
                // editor.off('NodeChange', handleNodeChange);
                editor.off('keydown', function (e) { handleNodeChange(e) });
            };
        }
        // eslint-disable-next-line
    }, [imagePaths]);



    // useEffect(() => {

    //     if (linka.length > 3) {
    //         setTimeout(() => {
    //             alert(linka)
    //         }, 1000);
    //     }
    // }, [linka]);
    useEffect(() => {
        const links = items.left.map(item => item.link);
        const linksString = links.join(',');
        setFormData(prevFormData => ({
            ...prevFormData,
            imgs: linksString,
            defimg: links[0],
            pixel_type: selectedValue,
            pixel_list: inputs,
            unids: unids,
        }));

    }, [items, selectedValue, inputs, unids]);


    const handleFileChange = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('unids', unids);
        formData.append('image', event.target.files[0]);


        try {
            const response = await axios.post(currentdomain + '/bk/upload_img.php', formData, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentage);
                },
            });
            //   setMessage(response.data.url);
            (response.data.success === 1) ? addrow(response.data.url) : alert(response.data.datas);

        } catch (error) {
            console.error('Error uploading image: ', error);
            //  setMessage('Error uploading image.');
        }
    };

    function link_to_name(url) {
        return url.substring(url.lastIndexOf('/') + 1);
    }

    const showToastMessageSuccus = () => {
        toast.success("! تمت العملية بنجاح ", {
        });
    };
    const showToastMessageError = () => {
        toast.error("!حدث خطأ فشل العملية", {
        });
    };


    const handleButtonClick = () => {
        setProgress(0);
        fileInputRef.current.click();
    };
    const EditorContentToStat = (content, editor) => {
        const id = editor.id;
        setFormData({
            ...formData,
            [id]: content,
        });
    };


    const handleChanga = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
        if (id === 'link') {
            const valueWithoutSpaces = e.target.value.replace(/\s+/g, '');
            setlinka(valueWithoutSpaces)

        }
    };

    async function rmimgrichtext(linka) {

        try {

            const name = link_to_name(linka);

            const response = await fetch(currentdomain + '/bk/up_imgs.php', {

                method: 'POST',
                headers: {

                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',

                },

                body: JSON.stringify({ name, unids }),

            });

            if (!response.ok) {

                throw new Error('Network response was not ok');

            }

            const data = await response.json();

            (data.success === 1) ?
                console.log('sucesssss') : alert(data.datas + name)


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleEditorChange = () => {
        const editor = editorRef.current;
        let currentImages = Array.from(editor.getDoc().querySelectorAll('img')).map(img => img.src);
        let removedImages = imagePaths.filter(path => !currentImages.includes(path));

        // Update states
        if (removedImages.length > 0) {
            setImagePaths(currentImages);
            rmimgrichtext(removedImages + '')
            removedImages = ''

        }
    };



    const handleSubmit = async () => {
        try {

            const response = await fetch(currentdomain + '/bk/add_new_prod.php', {
                //const response = await fetch('https://webhook.site/a964d2d2-f874-4f74-ba43-ba063962dbd7', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),

            });

            if (!response.ok) {
                throw new Error('Network response was not ok');

            }


            const data = await response.json();

            (data.success === 1) ?
                // alert(data.datas)
                showToastMessageSuccus()
                :
                showToastMessageError()
        } catch (error) {
            console.error('Error:', error);
            showToastMessageError()
        }
    };

    const addInput = () => {

        setInputs([...inputs, { id: Date.now(), value: '', name: '' }]);
    };

    const removeInput = (id) => {
        setInputs(inputs.filter(input => input.id !== id));
    };

    const handleInputChange = (id, newValue) => {
        setInputs(inputs.map(input => input.id === id ? { ...input, value: newValue } : input));
    };

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);

    };

    const handleBlur = async () => {
        try {
            const checkResponse = await fetch(currentdomain + '/bk/add_new_prod.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ linka })
            });


            const checkData = await checkResponse.json();
            (checkData.success === 1) ?
                setvalid_link(checkData.datas) : alert(checkData.datas);
            /*   if (!checkData.exists) {
                const postResponse = await fetch('http://your-backend-url/post-value.php', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ value })
                });
        
                const postData = await postResponse.json();
                console.log(postData.message);
              } else {
                console.log('Value already exists in the database.');
              }*/

        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta name="viewport" content={'width=1024'} />
                </Helmet>
            </HelmetProvider>

            <div className="cotainer  border   bg-gray-100   text-start ms-6 mt-3 grid grid-cols-4 dark:!bg-navy-900">

                <div className="vv p-3 col-span-3 bg-white dark:!bg-navy-900">

                    <div className="b border mb-6 mx-3">
                        <input id="titles" value={formData.titles} onChange={handleChanga} className="name  p-3 h-11 w-full border-solid border-2  border-gray-300  focus:border-green-600 focus:outline-none font-semibold text-lg dark:border-gray-700 dark:focus:border-white  dark:bg-gray-700 dark:text-white dark:placeholder-gray-200" type="text" placeholder="عنوان المنتج" required autoComplete="off" />
                    </div>
                    <div className="mb-6 mx-3 b border p-2 grid grid-cols-5  gap-1 items-center">
                        <input id="link" value={linka} onChange={handleChanga} className={`col-span-1 text-center h-9  border-solid border-2 ${(valid_link) ? "border-red-500 bg-red-500" : "border-gray-300"} focus:border-green-600 focus:outline-none font-semibold text-lg dark:border-gray-700 dark:focus:border-white  dark:bg-gray-700 dark:text-white dark:placeholder-gray-200`} type="text" placeholder="الرابط" required autoComplete="off" onBlur={handleBlur} disabled={isedit} />

                        <span className="col-span-2 mt-1 font-bold text-sm lgg:text-lg dark:text-white dark:placeholder-gray-200">
                            {":/" + window.location.origin.split(':3000')[0] + "/products"}
                        </span>
                    </div>

                    <div className="mb-12 px-2 bg-white  border-2 rounded-lg  shadow-lg" >
                        <div className="titlprice flex justify-start border-b-2 p-3 cursor-pointer mt-2" onClick={() => showdiscription()}  >
                            <FontAwesomeIcon className="mt-2" icon={showdiscript ? faAngleUp : faAngleDown} />
                            <span className=" ms-3 font-bold text-lg">الوصف</span>
                        </div>
                        <div className={showdiscript ? "" : "hidden"}>
                            {/* <Editor placeholder={'Write something...'} name={imgstate} /> */}


                            <Editor
                                value={formData.discription}
                                id="discription"
                                apiKey='j2spxbpkhc2kaqp1j5q5dvgkex3420weska7l2nvhd2dsqvr'
                                onInit={(evt, editor) => editorRef.current = editor}
                                init={{
                                    toolbar_mode: 'sliding',
                                    plugins: 'fullscreen code anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount   linkchecker textcolor',
                                    toolbar: 'fullscreen undo redo code blocks fontfamily fontsize forecolor backcolor bold italic underline strikethrough link image media table align lineheight  numlist bullist indent outdent emoticons charmap removeformat',
                                    tinycomments_mode: 'embedded',
                                    tinycomments_author: 'Author name',
                                    directionality: 'rtl',
                                    images_upload_url: currentdomain + '/bk/up_imgs.php',
                                    automatic_uploads: true,
                                    file_picker_types: 'image',
                                    images_reuse_filename: true,
                                    height: 600,
                                    // ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),

                                    // images_upload_handler: handleImageUpload,
                                    images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => {
                                        const xhr = new XMLHttpRequest();
                                        xhr.withCredentials = false;
                                        xhr.open('POST', currentdomain + '/bk/up_imgs.php');

                                        xhr.upload.onprogress = (e) => {
                                            progress(e.loaded / e.total * 100);
                                        };

                                        xhr.onload = () => {
                                            if (xhr.status === 403) {
                                                reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
                                                return;
                                            }

                                            if (xhr.status < 200 || xhr.status >= 300) {
                                                reject('HTTP Error: ' + xhr.status);
                                                return;
                                            }

                                            const json = JSON.parse(xhr.responseText);

                                            if (!json || typeof json.location != 'string') {
                                                reject('Invalid JSON: ' + xhr.responseText);
                                                return;
                                            }

                                            setImagePaths(prevImagePaths => [...prevImagePaths, json.location]);


                                            resolve(json.location);

                                        };

                                        xhr.onerror = () => {
                                            reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                                        };

                                        const formData = new FormData();
                                        formData.append('file', blobInfo.blob(), blobInfo.filename());
                                        formData.append('unids', unids);



                                        xhr.send(formData);
                                    }),

                                }}
                                //   initialValue=""
                                onEditorChange={EditorContentToStat}
                            />

                        </div>
                    </div>
                    <div className="pricing bg-white mb-11 border-2 rounded-lg  shadow-lg">
                        <ul>
                            <li>
                                <div className="titlprice flex justify-start border-b-2 p-3 cursor-pointer mt-2" onClick={() => showprices()}  >
                                    <FontAwesomeIcon className="mt-2" icon={showprice ? faAngleUp : faAngleDown} />
                                    <span className=" ms-3 font-bold text-lg">التسعير</span>
                                </div>
                            </li>
                            <li>
                                <div className={showprice ? "prices mt-3 mb-6 ms-3 me-6 grid grid-cols-3 gap-6 text-lg font-semibold" : "hidden"}>

                                    <div>
                                        <span className="mb-18 ms-2 ">السعر</span>
                                        <input id="price" value={formData.price} onChange={handleChanga} className="price  ps-2  rounded-lg h-11 w-full  border-2 border-gray-200 focus:border-green-600 focus:outline-none " type="number" step="0.01" /></div>
                                    <div>
                                        <span className="mb-18 ms-2 ">مقارنة السعر</span>
                                        <input id="oldprice" value={formData.oldprice} onChange={handleChanga} className="oldprice ps-2  rounded-lg  h-11 w-full  border-2 border-gray-200 focus:border-green-600 focus:outline-none  " type="number" step="0.01" /></div>
                                    <div>
                                        <span className="mb-18 ms-2 ">سعر التكلفة</span>
                                        <input id="cost" onChange={handleChanga} className="costprice ps-2  rounded-lg h-11 w-full  border-2 border-gray-200 focus:border-green-600 focus:outline-none" type="text" /></div>
                                </div>

                            </li>
                        </ul>

                    </div>

                    <div className="photos bg-white mb-11 border-2 rounded-lg  shadow-lg">
                        <ul>
                            <li className="flex justify-between border-b-2 p-3">
                                <div className="titlphoto flex justify-start cursor-pointer mt-2" onClick={() => showphoto()} >
                                    <FontAwesomeIcon className="mt-2" icon={showphotos ? faAngleUp : faAngleDown} />
                                    <span className=" ms-3 font-bold text-lg">الصور</span>
                                </div>
                                <div className="grid grid-cols-2 gap-3" >
                                    <button className="gohome w-32  p-1 bg-green-600  text-white font-bold text-sm rounded-2xl  h-11 mdd:h-10  hover:cursor-not-allowed disabled:bg-gray-400 " disabled onClick={() => alert(formData.titles)} >{progress} اضف رابط الفيديو</button>
                                    <button className="gohome w-32  p-1 bg-green-600  text-white font-bold text-sm rounded-2xl   h-11 mdd:h-10  hover:cursor-pointer hover:bg-green-400" onClick={handleButtonClick} >رفع الصور</button>
                                    <input ref={fileInputRef} type="file" accept="image/*" onChange={handleFileChange} className="hidden" />


                                </div>
                            </li>
                            <li>
                                <div className={showphotos ? "photo border p-3" : "hidden"} >
                                    {(items.left.length === 0) ?
                                        <div className='flex justify-center font-bold'>
                                            <span>ملاحظة: للحصول على تصميم ممتاز، استخدم صور المنتج بحجم( 800 × 800 )  {unids}</span>
                                        </div>

                                        :
                                        <div>
                                            <ul>
                                                <li className='flex justify-center'>

                                                    <GridContextProvider onChange={onChanges} >
                                                        <GridDropZone
                                                            //  className={` w-96  border-2  border-green-300  rounded-lg   m-3 p-2   h-${bordersize} `}
                                                            className='w-96 pe-1 border-2  border-green-300  rounded-lg  m-3  h-[435px] flex justify-end'
                                                            id="left"
                                                            boxesPerRow={3}
                                                            rowHeight={140}
                                                        >
                                                            {items.left.map((item) => (
                                                                (items.left.length !== 0) &&
                                                                <GridItem key={item.position} className="ps-4">

                                                                    {/* {item.name.toUpperCase()} */}
                                                                    < div className="w-full h-fit flex  justify-center items-start mb-2" >
                                                                        <span className='orderbt  text-red-600 font-bold text-lg  rounded-sm   h-4 cursor-pointer' onClick={() => rmvrow(item.position)}>X</span>
                                                                    </div>
                                                                    <img className='cursor-grab border-solid border-2  border-green-600 hover:border-red-500 rounded-lg  text-white flex justify-center items-center w-28 h-28' onDragStart={(e) => { e.preventDefault() }}
                                                                        src={item.link}
                                                                        alt="new"
                                                                    />
                                                                </GridItem>
                                                            ))}
                                                        </GridDropZone>

                                                    </GridContextProvider>
                                                    <div className='py-3 '>
                                                        <img className='cursor-grab border-solid border-2 border-green-600 rounded-lg   text-white flex justify-center items-center w-56 h-[435px]' onDragStart={(e) => { e.preventDefault() }}
                                                            src={items.left[0].link}
                                                            alt="new"
                                                        />
                                                    </div>
                                                </li >
                                            </ul >
                                        </div >
                                    }
                                </div >
                            </li >
                        </ul >

                    </div >
                    <div className=" bg-white mb-11 border-2 rounded-lg  shadow-lg">
                        <ul>
                            <li>
                                <div className=" flex justify-start border-b-2 p-3 cursor-pointer mt-2" onClick={() => showpixel()} >
                                    <FontAwesomeIcon className="mt-2" icon={pixelshow ? faAngleUp : faAngleDown} />
                                    <span className=" ms-3 font-bold text-lg">تخصيص PIXEL</span>
                                </div>
                            </li>
                            <li className="border-b-2">
                                <div className={pixelshow ? "mt-3 mb-6 mx-6 px-16" : "hidden"}>
                                    <div className="grid grid-cols-3 gap-3 mb-6 ms-2">
                                        <div>
                                            <input
                                                type="radio"
                                                id="option1"
                                                name="options"
                                                value="0"
                                                checked={selectedValue === '0'}
                                                onChange={handleRadioChange}
                                                className="form-radio accent-[#2bfc0f]"
                                            />
                                            <label htmlFor="option1">افتراضي</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="option2"
                                                name="options"
                                                value="1"
                                                checked={selectedValue === '1'}
                                                onChange={handleRadioChange}
                                                className="form-radio accent-[#2bfc0f]"
                                            />
                                            <label htmlFor="option2">تعطيل</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="option3"
                                                name="مخصص"
                                                value="2"
                                                checked={selectedValue === '2'}
                                                onChange={handleRadioChange}
                                                className="form-radio accent-[#2bfc0f]"
                                            />
                                            <label htmlFor="option3">مخصص</label>
                                        </div>
                                    </div>
                                    <div className={selectedValue === '2' ? "grid grid-cols-1 gap-3" : "hidden"}>
                                        {inputs.map(input => (
                                            <ul key={input.id}>
                                                <li className="mb-2 ms-2">
                                                    <span className="me-3 font-bold" >Pixel {input.id}</span>
                                                </li>
                                                <li className=" flex justify-between gap-3">
                                                    <input name="addpixel" placeholder="اضف بيكسل" className="price  ps-2 rounded-lg h-11 w-full  border-2 border-gray-200 focus:border-green-600 focus:outline-none text-sm" type="text"
                                                        value={input.value}
                                                        onChange={(e) => handleInputChange(input.id, e.target.value)} />
                                                    <span className='orderbt pt-2 text-red-600 font-bold text-2xl  rounded-sm   h-4 cursor-pointer' onClick={() => removeInput(input.id)}>X</span>

                                                </li>
                                            </ul>
                                        ))}

                                        <div className="flex justify-center mt-2">
                                            <button className="addpixel w-11 me-5   p-1 bg-green-600  text-white font-bold text-sm rounded-sm   h-11 mdd:h-10  hover:cursor-pointer hover:bg-green-400 disabled:bg-green-300 disabled:hover:cursor-not-allowed" disabled={(inputs.length) > 3} onClick={addInput} >اضافة</button>
                                        </div>
                                    </div>

                                </div>
                            </li>

                        </ul>
                    </div>

                    <div className=" bg-white mb-11 border-2 rounded-lg  shadow-lg">
                        <ul>
                            <li className="flex justify-between border-b-2 p-3">
                                <div className=" flex justify-start cursor-pointer" onClick={() => showvaria()}>
                                    <FontAwesomeIcon className=" mt-2" icon={shovariace ? faAngleUp : faAngleDown} />
                                    <span className=" ms-3 font-bold text-lg">المتغيرات</span>
                                </div>
                                <div >
                                    <label className="relative inline-flex items-center my-2 cursor-pointer">
                                        <input type="checkbox" value="" className="sr-only peer" />
                                        <div className="w-10 h-5  bg-gray-200 peer-focus:outline-none peer-focus:ring-3 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className={shovariace ? "photo border p-3" : "hidden"}>
                                    <div>
                                        <span>المتغيرات غير مفعلة</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className=" bg-white mb-11 border-2 rounded-lg  shadow-lg">
                        <ul>
                            <li className="flex justify-between border-b-2 p-3">
                                <div className=" flex justify-start cursor-pointer " onClick={() => showsim()} >
                                    <FontAwesomeIcon className="mt-2" icon={similaire ? faAngleUp : faAngleDown} />
                                    <span className=" ms-3 font-bold text-lg">المنتجات ذات صلة</span>
                                </div>
                                <div>
                                    <label className="relative inline-flex items-center my-2 cursor-pointer">
                                        <input type="checkbox" value="" className="sr-only peer" />
                                        <div className="w-10 h-5  bg-gray-200 peer-focus:outline-none peer-focus:ring-3 peer-focus:ring-blue-300  rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-green-600"></div>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className={similaire ? "photo border p-3" : "hidden"}>
                                    <div className="pricing bg-white mb-11 border-2 rounded-lg  shadow-lg">
                                        <ul>
                                            <li>
                                                <div className="titlprice flex justify-start border-b-2 p-3 " >
                                                    <span className=" ms-3 font-bold text-sm">أضف المنتجات ذات صلة</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mt-3 mb-6 ms-3 me-6 ">
                                                    <div>
                                                        <input className="ps-2 rounded-lg h-11 w-full border-2 border-gray-200 focus:border-green-600 focus:outline-none text-sm dark:border-gray-700 dark:focus:border-white  dark:bg-gray-700 dark:text-white dark:placeholder-gray-200" type="text" placeholder="البحث عن المنتجات" /></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>


                    <div className=" bg-white mb-11 border-2 rounded-lg  shadow-lg">
                        <ul>
                            <li>
                                <div className=" flex justify-start border-b-2 p-3 cursor-pointer mt-2" onClick={() => showazzo()} >
                                    <FontAwesomeIcon className="mt-2" icon={azos ? faAngleUp : faAngleDown} />
                                    <span className=" ms-3 font-bold text-lg">تحسين محركات البحث</span>
                                </div>
                            </li>
                            <li className="border-b-2">
                                <div className={azos ? "mt-3 mb-6 mx-6" : "hidden"}>
                                    <div className="grid grid-cols-2 gap-3 ">
                                        <ul>
                                            <li>
                                                <span className="mb-21 ms-2 ">الرابط</span>
                                            </li>
                                            <li>
                                                <input name="price" placeholder="الرابط" className="price  ps-2 rounded-lg h-11 w-full  border-2 border-gray-200 focus:border-green-600 focus:outline-none text-sm dark:border-gray-700 dark:focus:borer-white  dark:bg-gray-700 dark:text-white dark:placeholder-gray-200" type="text" />
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <span className="mb-21 ms-2 "> العنوان</span>
                                            </li>
                                            <li>
                                                <input name="oldprice" className="oldprice ps-2  rounded-lg  h-11  w-full border-2 border-gray-200 focus:border-green-600 focus:outline-none  text-sm dark:border-gray-700 dark:focus:border-white  dark:bg-gray-700 dark:text-white dark:placeholder-gray-200" type="text" placeholder="العنوان" />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mt-4">
                                        <ul>
                                            <li className="mb-2 ms-2 ">
                                                الوصف
                                            </li>
                                            <li>
                                                <textarea rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border-2 border-gray-300  focus:border-green-500 focus:outline-none  dark:border-gray-700 dark:focus:border-white  dark:bg-gray-700 dark:text-white dark:placeholder-gray-200" placeholder="الوصف"></textarea>
                                            </li>
                                            <li className=" ms-2 mt-3">  الصور الوصفية  </li>
                                            <li className=" flex items-center justify-center w-full h-56  rounded-lg text-center cursor-pointer">
                                                <label className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 dark:border-gray-700 dark:focus:border-white  dark:bg-gray-700 dark:text-white dark:placeholder-gray-200">
                                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                        <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                        </svg>
                                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className=" text-black font-bold">اختر الملف الذي ترغب بتحميله</span> </p>
                                                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                                    </div>
                                                    <input id="dropzone-file" type="file" className="hidden" />
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>

                    <div>

                        <div className="flex justify-between mx-3 my-6">
                            {/* <button className="gohome w-32 me-5  p-1 bg-amber-700  text-white font-bold text-sm rounded-2xl  h-11 mdd:h-10  hover:cursor-pointer hover:bg-amber-400" onClick={() => handleSubmit()} >معاينة</button>*/}
                            <button className=" bg-green-600 me-5 rounded-xl cursor-pointer content-center w-36 " onClick={navigateToPreview}>
                                <span className="gohome   p-1 text-lg text-center font-bold  text-white ">
                                    معاينة  <FontAwesomeIcon icon={faEye} style={{ color: "#FFFFFF", }} />
                                </span>
                            </button>


                            <div className="flex justify-center">
                                <button className="gohome w-32 me-5  p-1 bg-green-600  text-white font-bold text-sm rounded-xl   h-11 mdd:h-10  hover:cursor-pointer hover:bg-green-400" onClick={() => handleSubmit()} >حفظ</button>
                                <button className="gohome w-32 me-5 p-1 bg-red-600  text-white font-bold text-sm rounded-xl   h-11 mdd:h-10  hover:cursor-pointer hover:bg-red-400" onClick={() => alert(formData.discription)} > الغاء</button>
                            </div>
                            {/* <button className="gohome w-32  p-1 bg-amber-700  text-white font-bold text-sm rounded-2xl   h-11 mdd:h-10  hover:cursor-pointer hover: hover:bg-amber-400" onClick={() => alert(formData.discription)} > المنتجات</button> */}
                            <button className=" bg-green-600  rounded-xl cursor-pointer content-center w-36" onClick={navigateToProducts}>
                                <span className="gohome  text-lg text-center  p-1 font-bold text-white">
                                    منتجات  <FontAwesomeIcon icon={faBasketShopping} style={{ color: "#FFFFFF", }} />
                                </span>
                            </button>
                        </div>
                    </div>
                </div >
            </div >

            <ToastContainer position="top-right" />

        </>
    );
}

export default Addproduct;